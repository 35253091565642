<script>
  import dayjs from 'dayjs'
  import { taskForm } from '@/stores/TaskFormStore'
  import { boardStore } from '@/stores/TaskBoardStore.js'

  let startDate = $boardStore.selectedTask
    ? dayjs($boardStore.selectedTask.start_time).format('YYYY-MM-DD')
    : null
  let startTime = $boardStore.selectedTask
    ? dayjs($boardStore.selectedTask.start_time).format('HH:mm')
    : dayjs().format('HH:mm')
  let endTime = $boardStore.selectedTask
    ? dayjs($boardStore.selectedTask.end_time).format('HH:mm')
    : dayjs().add(30, 'minutes').format('HH:mm')

  function setTaskDateTimes() {
    $taskForm.start_time = dayjs(`${startDate} ${startTime}`).format(
      'YYYY-MM-DD HH:mm',
    )
    $taskForm.end_time = dayjs(`${startDate} ${endTime}`).format(
      'YYYY-MM-DD HH:mm',
    )
  }
</script>

<div class="time-container">
  <div>
    <div class="form-group">
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div>
            <label for="startTime">Start tidspunkt</label>
            <input
              type="time"
              id="startTime"
              name="startTime"
              class="form-control"
              bind:value={startTime}
              on:change={setTaskDateTimes}
            />
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div>
            <label for="endTime">Slut tidspunkt</label>
            <input
              type="time"
              id="endTime"
              name="endTime"
              class="form-control"
              bind:value={endTime}
              on:change={setTaskDateTimes}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="startDate">Første dag</label>
    <input
      type="date"
      name="startDate"
      id="startDate"
      class="form-control"
      bind:value={startDate}
      on:change={setTaskDateTimes}
      required
    />
  </div>
</div>
