<script>
  import Modal from '@/components/Modal.svelte'
  import Task from '@/pages/Tasks/components/Task.svelte'
  import { boardStore } from '@/stores/TaskBoardStore'
  import { Icon, ArrowPath } from 'svelte-hero-icons'
</script>

<Modal bind:showModal={$boardStore.showTaskModal}>
  <div slot="header">
    <div>
      Opgave: {$boardStore.selectedTask.name}
      {#if $boardStore.selectedTask.recurring}
        <span class="badge badge-pill badge-primary">
          <Icon src={ArrowPath} size="12" />
          Gentagende</span
        >
      {/if}
    </div>
  </div>

  <Task />
</Modal>
