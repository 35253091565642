<script>
  import { page } from '@inertiajs/svelte'
  import '../../../Tasks/floweffect.css'
  import Interaction from '@event-calendar/interaction'
  import List from '@event-calendar/list'
  import Calendar from '@event-calendar/core'
  import TaskModal from '@/components/Floweffect/modals/TaskModal.svelte'
  import NewTaskModal from '@/components/Floweffect/modals/NewTaskModal.svelte'
  import { getTaskStatusClasses } from '@/stores/TaskBoardStore'
  import { tasks, getTask, loadTasksForUser } from '@/stores/TaskStore'
  import { userStore } from '@/stores/UserStore'
  import { citizenStore } from '@/stores/CitizenStore'
  import { taskTemplate } from '@/pages/Tasks/components/taskTemplate'
  import { boardStore } from '@/stores/TaskBoardStore'
  import dayjs from 'dayjs'
  import { calendarDate } from '@/utils/dateHelper'
  import {
    taskChannelStore,
    subscribeToTaskChannel,
  } from '@/channels/TaskChannel'

  export let initTasks = []
  export let citizens = []
  export let users = []

  let calendar
  $tasks = initTasks
  $userStore = users
  $citizenStore = citizens

  subscribeToTaskChannel($page.props.auth.company.slug)
  $: if ($taskChannelStore.action === 'task_update') {
    loadTasksForUser(
      $boardStore.startTime,
      $boardStore.endTime,
      $page.props.auth.user.id,
    )
    taskChannelStore.reset()
  }

  function formatTasks(tasks) {
    let formattedTasks = []

    tasks.forEach((task) => {
      formattedTasks.push({
        id: task.id,
        title: task.name,
        allDay: false,
        start: task.start_time,
        end: task.end_time,
        classNames: ['sg-task', getTaskStatusClasses(task)],
        textColor: 'black',
        styles: 'font-size: 12px',
      })
    })

    return formattedTasks
  }

  function formatTaskContent(eventInfo) {
    let task = getTask(parseInt(eventInfo.event.id))
    if (task == null) return

    return taskTemplate({
      citizen: citizens.find((citizen) => citizen.id === task.citizen_id),
      label: task.name,
      start_time: task.start_time,
      end_time: task.end_time,
    })
  }

  function selectTask(eventInfo) {
    let task = getTask(parseInt(eventInfo.event.id))
    $boardStore.selectedTask = task
    $boardStore.showTaskModal = true
  }

  let plugins = [Interaction, List]
  $: options = {
    view: 'listDay',
    events: formatTasks($tasks),
    eventContent: (eventInfo) => {
      if (parseInt(eventInfo.event.id) == null) return

      return {
        html: formatTaskContent(eventInfo),
      }
    },
    headerToolbar: {
      start: 'today prev,next',
      center: '',
      end: 'newTask',
    },
    buttonText: {
      today: 'Gå til i dag',
    },
    customButtons: {
      newTask: {
        text: 'Ny opgave',
        click: () => {
          $boardStore.showNewTaskModal = true
        },
      },
    },
    datesSet: (dateInfo) => {
      $boardStore.startTime = dayjs(dateInfo.start)
      $boardStore.endTime = dayjs(dateInfo.end)

      loadTasksForUser(
        $boardStore.startTime,
        $boardStore.endTime,
        $page.props.auth.user.id,
      )
    },
    eventClick: (eventInfo) => {
      selectTask(eventInfo)
    },
  }
</script>

<h1 class="date-title">
  Opgaver {calendarDate($boardStore.startTime).toLowerCase()}
</h1>
<Calendar bind:this={calendar} {plugins} {options} />

<!-- Modals -->
<TaskModal />
<NewTaskModal />

<style>
  /* Removes header of the calendar because it shows redundant date */
  :global(.ec-day-head) {
    display: none;
  }

  .date-title {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
  }
</style>
