import { writable } from 'svelte/store'
import { get } from 'svelte/store'
import axios from 'axios'

function createStore() {
  const { subscribe, set, update } = writable([])

  return {
    subscribe,
    set,
    update,
    get: () => get({ subscribe }),
    loadCheckedInUsers: () => {
      axios
        .get('/user/checked_in')
        .then((response) => {
          set(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
    },
  }
}

export const checkedInUserStore = createStore()
