<script>
  import dayjs from 'dayjs'
  import './floweffect.css'
  import { boardStore, updateBoard } from '@/stores/TaskBoardStore'
  import { userStore } from '@/stores/UserStore'
  import { citizenStore } from '@/stores/CitizenStore'
  import TaskBoard from './components/TaskBoard.svelte'
  import { headerTemplate } from './components/headerTemplate'
  import DateSelection from './components/DateSelection.svelte'
  import BoardSelection from './components/BoardSelection.svelte'
  import BoardContainer from '@/components/Floweffect/BoardContainer.svelte'
  import { resetTaskForm } from '@/stores/TaskFormStore'
  import axios from 'axios'

  export let users
  export let citizens
  export let tasks
  export let allUsers

  let showNewTaskModal = false
  let showCheckInModal = false
  let boardTasks = []

  $boardStore.boardType = 'user'

  // This is used to force the board to update
  // whenever a task is upodated - a bit hacky
  // TODO: Should be refactored
  boardStore.subscribe((store) => {
    if (
      JSON.stringify(boardTasks.map((task) => task.id).sort()) !=
      JSON.stringify(store.boardTasks.map((task) => task.id).sort())
    ) {
      boardTasks = store.boardTasks
    }
  })

  function formattedUserData(user) {
    let userTasks = []
    if ($boardStore.tasks != null) {
      userTasks = $boardStore.tasks.filter((task) => {
        return task.user_id == user.id
      })
    }

    userTasks = userTasks.sort((a, b) => {
      return dayjs(a.start_time) - dayjs(b.start_time)
    })

    return {
      id: user.id,
      headerHtml: headerTemplate(user, userTasks),
    }
  }

  function updateUsers() {
    axios
      .get('/user/checked_in/')
      .then((response) => {
        let newUsers = []
        response.data.forEach((user) => {
          newUsers.push(formattedUserData(user))
        })
        $boardStore.rows = newUsers
        updateBoard()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function initializeData() {
    let formattedUsers = []
    let formattedTasks = []
    $userStore = allUsers
    $citizenStore = citizens

    users.forEach((user) => {
      formattedUsers.push(formattedUserData(user))
    })

    updateBoard()

    $boardStore.rows = formattedUsers
    $boardStore.tasks = tasks
    $boardStore.boardTasks = formattedTasks
    // TODO: Refactor this!!!! HAck to make activity dots work on first load
    updateUsers()
  }
  initializeData()

  function closeModal() {
    showNewTaskModal = false
    resetTaskForm()
  }

  $: console.log('Boardstore: ', $boardStore)
</script>

<!-- Board selection -->
<BoardSelection board={'user'} />

<DateSelection
  startTime={$boardStore.startTime}
  endTime={$boardStore.endTime}
/>

<!-- Gantt chart -->
<BoardContainer showActionButtons={false}>
  {#key boardTasks}
    <TaskBoard on:updateUsers={updateUsers} />
  {/key}
</BoardContainer>
