<script>
  import { page } from '@inertiajs/svelte'
  import BoardContainer from '@/components/Floweffect/BoardContainer.svelte'
  import Calendar from '@event-calendar/core'
  import Interaction from '@event-calendar/interaction'
  import ResourceTimeGrid from '@event-calendar/resource-time-grid'
  import ResourceTimeline from '@event-calendar/resource-timeline'
  import { getTaskStatusClasses } from '@/stores/TaskBoardStore'
  import { taskTemplate } from '@/pages/Tasks/components/taskTemplate'
  import { headerTemplate } from '@/pages/Tasks/components/headerTemplate'
  import { tasks, getTask, loadTasks, updateTask } from '@/stores/TaskStore'
  import { boardStore } from '@/stores/TaskBoardStore'
  import { userStore } from '@/stores/UserStore'
  import { citizenStore } from '@/stores/CitizenStore'
  import { checkedInUserStore } from '@/stores/CheckedInUserStore'
  import { capitalize } from '@/utils/helpers'
  import dayjs from 'dayjs'
  import {
    taskChannelStore,
    subscribeToTaskChannel,
  } from '@/channels/TaskChannel'
  import {
    checkInLogChannelStore,
    subscribeToCheckInLogChannel,
  } from '@/channels/CheckInLogChannel'

  export let citizens
  export let users
  export let checkedInUsers

  let calendar
  let resourceType = 'citizen'

  $boardStore.startTime = dayjs().startOf('day')
  $boardStore.endTime = dayjs().endOf('day')
  $userStore = users
  $citizenStore = citizens
  $checkedInUserStore = checkedInUsers

  // Initial load
  loadTasks($boardStore.startTime, $boardStore.endTime)

  // Subscribing to Rails socket server to listen for task updates
  subscribeToTaskChannel($page.props.auth.company.slug)
  $: if ($taskChannelStore.action === 'task_update') {
    loadTasks($boardStore.startTime, $boardStore.endTime)
    taskChannelStore.reset()
  }

  subscribeToCheckInLogChannel($page.props.auth.company.slug)
  $: if ($checkInLogChannelStore.action === 'check_in_log_update') {
    checkedInUserStore.loadCheckedInUsers()
    checkInLogChannelStore.reset()
  }

  function formatTasks(tasks) {
    let formattedTasks = []

    tasks.forEach((task) => {
      formattedTasks.push({
        id: task.id,
        title: task.name,
        allDay: false,
        start: task.start_time,
        end: task.end_time,
        resourceIds: [
          resourceType == 'citizen' ? task.citizen_id : task.user_id,
        ],
        classNames: ['sg-task', getTaskStatusClasses(task)],
        textColor: 'black',
        styles: 'font-size: 12px',
      })
    })

    return formattedTasks
  }

  function formatTaskContent(eventInfo) {
    let task = getTask(parseInt(eventInfo.event.id))
    if (task == null) return

    return taskTemplate({
      citizen:
        resourceType == 'citizen'
          ? users.find((user) => user.id === task.user_id)
          : citizens.find((citizen) => citizen.id === task.citizen_id),
      label: task.name,
      start_time: task.start_time,
      end_time: task.end_time,
    })
  }

  // TODO: Refactor
  function formatResources(resources) {
    let formattedResources = []

    resources.forEach((resource) => {
      formattedResources.push({
        id: resource.id,
        title: resource.name,
      })
    })

    return formattedResources
  }

  function formatResourceContent(resourceInfo) {
    let citizen
    if (resourceType == 'citizen') {
      citizen = citizens.find((u) => u.id == parseInt(resourceInfo.resource.id))
    } else {
      citizen = users.find((u) => u.id == parseInt(resourceInfo.resource.id))
    }

    if (citizen == null) return

    return headerTemplate(
      citizen,
      [],
      `/floweffect/citizens/${citizen.id}/weekly_calendars`,
    )
  }

  function selectTask(eventInfo) {
    if ($boardStore.userGrabbingTasks) {
      boardStore.addGrabbedTask({
        id: parseInt(eventInfo.event.id),
        start_time: eventInfo.event.start,
        end_time: eventInfo.event.end,
      })
    } else {
      let task = getTask(parseInt(eventInfo.event.id))

      $boardStore.selectedTask = task
      $boardStore.showTaskModal = true
    }
  }

  function changeTaskTime(boardTask) {
    let task = getTask(parseInt(boardTask.id))
    task.isRecurring = task.recurring != null

    let data = {
      id: boardTask.id,
      start_time: dayjs(boardTask.start).format(),
      end_time: dayjs(boardTask.end).format(),
      isRecurring: task.isRecurring,
    }

    if (resourceType == 'citizen') {
      data.citizen_id = boardTask.resourceIds[0]
    } else {
      data.user_id = boardTask.resourceIds[0]
    }

    updateTask(data)
  }

  let plugins = [Interaction, ResourceTimeGrid, ResourceTimeline]
  $: options = {
    view: 'resourceTimelineDay',
    events: formatTasks($tasks),
    resources: formatResources(
      resourceType == 'citizen' ? citizens : $checkedInUserStore,
    ),
    editable: true,
    longPressDelay: 100,
    locale: 'da-DK',
    scrollTime: $boardStore.scrollTime,
    allDaySlot: false,
    dayMaxEvents: true,
    nowIndicator: true,
    customButtons: {
      citizenView: {
        text: 'Borger',
        click: function () {
          resourceType = 'citizen'
        },
        active: resourceType == 'citizen',
      },
      userView: {
        text: 'Medarbejder',
        click: function () {
          resourceType = 'user'
        },
        active: resourceType == 'user',
      },
    },
    headerToolbar: {
      start: 'today prev,next',
      center: 'title',
      end: 'citizenView,userView resourceTimelineDay,resourceTimeGridDay',
    },
    buttonText: {
      resourceTimeGridDay: 'Horisontalt',
      resourceTimelineDay: 'Vertikalt',
      timeGridDay: 'Plan i dag',
      timeGridWeek: 'Ugeplan',
      today: 'Gå til i dag',
    },
    views: {
      resourceTimelineDay: {
        slotDuration: '00:30:00',
        slotWidth: 200,
        slotHeight: 52,
      },
      resourceTimeGridDay: {
        slotDuration: '00:15:00',
        slotHeight: 52,
        scrollTime: dayjs().format('HH:mm:ss'),
        slotEventOverlap: false,
      },
    },
    eventContent: (eventInfo) => {
      if (parseInt(eventInfo.event.id) == null) return

      return {
        html: formatTaskContent(eventInfo),
      }
    },
    resourceLabelContent: (resourceInfo) => {
      return {
        html: formatResourceContent(resourceInfo),
      }
    },
    datesSet: (dateInfo) => {
      $boardStore.startTime = dateInfo.start
      $boardStore.endTime = dateInfo.end

      loadTasks($boardStore.startTime, $boardStore.endTime)
    },
    eventDrop: (eventInfo) => {
      changeTaskTime(eventInfo.event)
    },
    eventResize: (eventInfo) => {
      changeTaskTime(eventInfo.event)
    },
    eventClick: (eventInfo) => {
      selectTask(eventInfo)
    },
    titleFormat: () => {
      return `${capitalize(
        dayjs($boardStore.startTime).format('dddd'),
      )} d. ${dayjs($boardStore.startTime).format('D MMMM YYYY')}`
    },
  }
</script>

<!-- Gantt chart -->
<BoardContainer>
  <Calendar bind:this={calendar} {plugins} {options} />
</BoardContainer>
