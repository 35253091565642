import { createConsumer } from '@rails/actioncable'
import { writable } from 'svelte/store'

function createStore() {
  const { subscribe, set, update } = writable('')

  return {
    subscribe,
    set,
    update,
    reset: () => set(''),
  }
}

export const taskChannelStore = createStore()

const consumer = createConsumer()

export function subscribeToTaskChannel(companySlug) {
  consumer.subscriptions.create(
    { channel: 'TaskChannel', company_slug: companySlug },
    {
      received(data) {
        taskChannelStore.set(data)
      },
    },
  )
}
