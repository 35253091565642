<script>
  import Modal from '@/components/Modal.svelte'
  import TaskForm from '@/components/Floweffect/TaskForm.svelte'
  import { boardStore } from '@/stores/TaskBoardStore'
</script>

<Modal bind:showModal={$boardStore.showNewTaskModal}>
  <span slot="header">Opret ny opgave</span>
  <TaskForm />
</Modal>
