<script>
  // TODO: Round to nearest 15 minutes on start/end date init

  import { onMount, createEventDispatcher, onDestroy } from 'svelte'
  import { taskForm, resetTaskForm } from '@/stores/TaskFormStore'
  import { boardStore } from '@/stores/TaskBoardStore'
  import { modalStore } from '@/stores/ModalStore'
  import { userStore } from '@/stores/UserStore'
  import { citizenStore } from '@/stores/CitizenStore'
  import RecurringOptions from '@/components/Floweffect/RecurringOptions.svelte'
  import axios from 'axios'
  import dayjs from 'dayjs'

  export let editing = false
  export let onlyRecurring = false
  export let citizen = null

  const dispatch = createEventDispatcher()
  let weeklyRuleError

  // Setting correct recurring rule - if any!
  let recurringType = 'No'
  if (onlyRecurring) {
    recurringType =
      $boardStore.selectedTask && $boardStore.selectedTask.recurring
        ? $boardStore.selectedTask.recurring.rule_type
        : 'IceCube::DailyRule'
  } else {
    recurringType =
      $boardStore.selectedTask && $boardStore.selectedTask.recurring
        ? $boardStore.selectedTask.recurring.rule_type
        : 'No'
  }

  let startTime = dayjs().format('YYYY-MM-DD HH:mm')
  let endTime = dayjs().add(30, 'min').format('YYYY-MM-DD HH:mm')
  // Setting taskType and formData
  $: taskType = recurringType == 'No' && !onlyRecurring ? 'single' : 'recurring'
  $: formData =
    taskType == 'single'
      ? { single_task: $taskForm }
      : { recurring_task: $taskForm }

  onDestroy(() => {
    if (!editing) {
      $boardStore.selectedTask = null
    }
    $boardStore.safeToReload = true
  })

  onMount(() => {
    if (!editing) {
      resetTaskForm()
      if (citizen) $taskForm.citizen_id = citizen.id
    } else {
      startTime = dayjs($boardStore.selectedTask.start_time).format(
        'YYYY-MM-DD HH:mm',
      )
      endTime = dayjs($boardStore.selectedTask.end_time).format(
        'YYYY-MM-DD HH:mm',
      )
    }
    if ($citizenStore.length === 1) $taskForm.citizen_id = $citizenStore[0].id
    setTaskDateTimes()
    $boardStore.safeToReload = false
  })

  function setTaskDateTimes() {
    $taskForm.start_time = startTime
    $taskForm.end_time = endTime
  }

  function handleSubmit() {
    let url = `/tasks/${taskType}_tasks`

    if (recurringType === 'IceCube::WeeklyRule') {
      let selectedDays = JSON.parse($taskForm.recurring).validations.day
      if (selectedDays.length === 0) {
        weeklyRuleError = true
        return
      }
    }

    if (editing) {
      axios
        .put(`${url}/${$boardStore.selectedTask.id}`, formData)
        .then((response) => {
          $boardStore.selectedTask = response.data
          dispatch('savedTask')
          modalStore.closeModal()
        })
        .catch((error) => {
          console.log('ERROR:', error)
        })
    } else {
      axios
        .post(url, formData)
        .then(() => {
          taskForm.reset()
          modalStore.closeModal()
          $boardStore.showNewTaskModal = false
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  function deleteTask() {
    let message = $boardStore.selectedTask.recurring
      ? 'Er du sikker på at du vil slette hele serien af opgaver?'
      : 'Er du sikker på at du vil slette opgaven?'
    confirm(message)

    axios
      .delete(`/tasks/${$boardStore.selectedTask.id}`)
      .then(() => {
        dispatch('updateTaskBoard')
        modalStore.closeModal()
      })
      .catch((error) => {
        console.log(error)
      })
  }
</script>

<div class="flex flex-col text-left">
  <form on:submit|preventDefault={handleSubmit}>
    <div class="form-group">
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <label for="user">Medarbejder</label>
          <select
            name="user_id"
            id="user"
            class="form-control"
            bind:value={$taskForm.user_id}
          >
            <option value=""></option>
            {#each $userStore as user}
              <option value={user.id} selected={user.id == $taskForm.user_id}>
                {user.name}
              </option>
            {/each}
          </select>
        </div>
        <div class="col-xs-12 col-sm-6">
          <label for="citizen">Borger</label>
          <select
            name="citizen_id"
            id="citizen"
            class="form-control"
            bind:value={$taskForm.citizen_id}
          >
            {#each $citizenStore as citizen}
              <option
                value={citizen.id}
                selected={citizen.id == $taskForm.citizen_id}
              >
                {citizen.name}
              </option>
            {/each}
          </select>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="name">Opgavetitel</label>
      <input
        type="text"
        name="name"
        id="name"
        bind:value={$taskForm.name}
        class="form-control"
        required
      />
    </div>
    <div class="form-group">
      <label for="description">Beskrivelse</label>
      <textarea
        type="text"
        name="description"
        id="description"
        bind:value={$taskForm.description}
        class="form-control"
      />
    </div>

    <div class="form-group">
      <label for="recurring-interval">Gentag opgaven</label>
      <select
        name="recurring-interval"
        id="recurring-interval"
        class="form-control"
        bind:value={recurringType}
        required
      >
        {#if !onlyRecurring}
          <option value="No">Nej</option>
        {/if}
        <option value="IceCube::DailyRule">Dagligt</option>
        <option value="IceCube::WeeklyRule">Ugentligt</option>
      </select>
    </div>

    {#if recurringType == 'No'}
      <div class="form-group">
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <label for="start-datetime">Start tidspunkt</label>
            <input
              type="datetime-local"
              name="start-datetime"
              class="form-control"
              bind:value={startTime}
              on:change={setTaskDateTimes}
            />
          </div>
          <div class="col-xs-12 col-sm-6">
            <label for="end-datetime">Slut tidspunkt</label>
            <input
              type="datetime-local"
              name="end-datetime"
              class="form-control"
              bind:value={endTime}
              on:change={setTaskDateTimes}
            />
          </div>
        </div>
      </div>
    {:else}
      {#if weeklyRuleError}
        <strong style="color: red">
          Fejl: Du skal huske at vælge hvilken dag(e) opgaven skal gentages
        </strong>
      {/if}
      <RecurringOptions {recurringType} />
    {/if}

    <button type="submit" class="btn btn-success btn-block submit-button">
      {#if editing}
        Gem opgaven
      {:else}
        Opret opgaven
      {/if}
    </button>
  </form>
  {#if editing}
    <button
      class="btn btn-default btn-block"
      style="margin-top: 1rem;"
      on:click={() => {
        deleteTask()
      }}
    >
      Slet
    </button>
  {/if}
</div>

<style>
  .submit-button {
    margin-top: 30px;
  }
</style>
