import axios from 'axios'
import { writable } from 'svelte/store'

export const tasks = writable([])

export function loadTasks(startTime, endTime, citizenId) {
  let paramString = `?start_time=${startTime}&end_time=${endTime}`
  paramString += citizenId ? `&citizen_id=${citizenId}` : ''

  axios
    .get('/tasks' + paramString)
    .then((response) => {
      tasks.set(response.data)
    })
    .catch((error) => {
      console.error(error)
    })
}

export function loadTasksForUser(startTime, endTime, userId) {
  axios
    .get(
      `/floweffect/users/${userId}/tasks?start_time=${startTime}&end_time=${endTime}`,
      { headers: { Accept: 'application/json' } },
    )
    .then((response) => {
      tasks.set(response.data)
    })
    .catch((error) => {
      console.error(error)
    })
}

export function getTask(taskId) {
  let foundTask = null

  tasks.subscribe((currentTasks) => {
    foundTask = currentTasks.find((task) => task.id === taskId)
  })
  return foundTask
}

export function updateTask(task) {
  if (task.isRecurring) {
    return moveRecurringTask(task) // Only for moving single occurrence
  } else {
    return updateSingleTask(task)
  }
}

function moveRecurringTask(task) {
  let oldTask = getTask(parseInt(task.id))
  axios
    .put(`/floweffect/tasks/move_recurring_tasks/${task.id}`, {
      task: {
        start_time: task.start_time,
        end_time: task.end_time,
        old_start_time: oldTask.start_time,
        old_end_time: oldTask.end_time,
      },
    })
    .catch((error) => {
      console.log(error)
    })
}

function updateSingleTask(task) {
  axios
    .put(`/tasks/single_tasks/${task.id}`, task)
    .then((response) => {
      tasks.update((store) => {
        const index = store.findIndex((t) => t.id === response.data.id)
        store[index] = response.data
        return store
      })
    })
    .catch((error) => {
      console.error(error)
    })
}
