export function headerTemplate(resource, tasks = [], resourceUrl) {
  let taskDots = tasks.map((task) => {
    const status = taskStatus(task)
    return `<div class="task-dot task-dot-${status}"></div>`
  })
  let flagMessages = tasks.map(() => {
    return `
      <div class="flag-message-icon">
        <svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"></path></svg>
      </div>
    `
  })
  if (resourceUrl) {
    return `
    <div class="header-template">
      <a href="${resourceUrl}" class="citizen-link">
        <div>
          <img
            src="${resource.avatar.thumb.url}"
            height="40px"
            width="40px"
            class="img-circle"
          />
        </div>
        <div class="header-content-container">
          <div><strong>${resource.name}</strong></div>
          <div style="display: flex; flex-direction: row; gap: 5px; color: #fe9f5b;">
            ${flagMessages.join('')}
          </div>
        </div>
      </a>
    </div>
    `
  } else {
    return `
    <div class="header-template w-full flex gap-6 items-center">
      <img src="${
        resource.avatar.thumb.url
      }" height="40px" width="40px" class="img-circle" />
      <div class="header-content-container">
        <div><strong>${resource.name}</strong></div>
        <div class="task-dots">
          ${taskDots.join('')}
        </div>
      </div>
    </div>
    `
  }
}

function taskStatus(task) {
  const currentTime = new Date()
  const endTime = new Date(task.end_time)

  if (endTime < currentTime && !task.completed_at) {
    return 'late'
  }
  if (task.completed_at) {
    return 'done'
  }

  return 'active'
}
