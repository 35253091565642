import dayjs from 'dayjs'

export const formatDate = (date, format = 'YYYY-MM-DD HH:mm') => {
  return dayjs(date).format(format)
}

export const calendarDate = (date) => {
  return dayjs(date).calendar(null, {
    sameDay: '[I dag]',
    nextDay: '[I morgen]', // The next day ( Tomorrow at 2:30 AM )
    nextWeek: '[På] dddd', // The next week ( Sunday at 2:30 AM )
    lastDay: '[I går]', // The day before ( Yesterday at 2:30 AM )
    lastWeek: '[Sidste] dddd [d.] DD MMMM', // Last week ( Last Monday at 2:30 AM )
    sameElse: 'dddd [d.] DD MMMM YYYY', // Everything else ( 17/10/2011 )
  })
}
