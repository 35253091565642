import { writable, get } from 'svelte/store'
import dayjs from 'dayjs'
import { formatDate } from '@/utils/dateHelper'
import axios from 'axios'
import { taskTemplate } from '@/pages/Tasks/components/taskTemplate'

// TODO: Is this best practice?
import { getUserById } from '@/stores/UserStore'

function defaultStartTime() {
  dayjs().startOf('day')
}
function defaultEndTime() {
  dayjs().endOf('day')
}

function createStore() {
  const { subscribe, set, update } = writable({
    selectedTask: null,
    taskGrabbingActivated: false,
    grabbedTasks: [],
    userGrabbingTasks: null,
    showTaskModal: false,
    showNewTaskModal: false,
    safeToReload: true,
    startTime: defaultStartTime(),
    endTime: defaultEndTime(),
    tasksToRemove: [],
    boardType: null,
    scrollTime: dayjs().format('HH:mm:ss'),
  })

  return {
    subscribe,
    set,
    update,
    get: () => get({ subscribe }),
    addGrabbedTask: (task) => {
      update((store) => {
        if (store.grabbedTasks.findIndex((t) => t.id == task.id) >= 0) {
          store.grabbedTasks = store.grabbedTasks.filter(
            (t) => t.id !== task.id,
          )
        } else {
          store.grabbedTasks.push(task)
        }
        return store
      })
    },
    resetGrabbedTasks: () => {
      update((store) => {
        store.grabbedTasks = []
        store.taskGrabbingActivated = false
        store.userGrabbingTasks = null
        store.selectedTask = null
        return store
      })
    },
    resetScrollTime: () => {
      update((store) => {
        store.scrollTime = dayjs().format('HH:mm:ss')
        return store
      })
    },
    resetDateTime: () => {
      update((store) => {
        store.startTime = defaultStartTime()
        store.endTime = defaultEndTime()
        return store
      })
    },
  }
}

export const boardStore = createStore()

export function updateBoard(selectedCitizen) {
  let paramString = '?'
  paramString += `start_time=${boardStore.get().startTime}&end_time=${
    boardStore.get().endTime
  }`
  paramString += selectedCitizen ? `&citizen_id=${selectedCitizen.id}` : ''

  axios
    .get('/tasks' + paramString)
    .then((response) => {
      boardStore.update((store) => ({
        ...store,
        tasks: response.data,
        boardTasks: formattedTaskData(response.data),
      }))
    })
    .catch((error) => {
      console.error(error)
    })
}

export function reloadTasks() {
  boardStore.update((store) => {
    store.boardTasks = formattedTaskData(store.tasks)
    return store
  })
}

function formattedTaskData(tasks) {
  let formattedTasks = []

  tasks.forEach((task) => {
    formattedTasks.push({
      id: task.id,
      resourceId:
        boardStore.get().boardType == 'citizen'
          ? task.citizen_id
          : task.user_id,
      from: dayjs(task.start_time),
      from_formatted: dayjs(task.start_time).format('HH:mm'),
      to_formatted: dayjs(task.end_time).format('HH:mm'),
      to: dayjs(task.end_time),
      html: taskTemplate({
        citizen: getUserById(task.user_id),
        label: task.name,
        start_time: task.start_time,
        end_time: task.end_time,
      }),
      classes: getTaskStatusClasses(task),
      label: task.name,
      name: task.name,
      task_id: task.id,
      card_type: boardStore.get().boardType == 'citizen' ? 'citizen' : 'user',
      recurring: task.recurring,
      user_id: task.user_id,
    })
  })

  return formattedTasks
}

export function getTaskStatusClasses(task) {
  let classes = []

  if (task.completed_at != null) {
    classes.push('done')
  } else if (
    dayjs(task.start_time) < dayjs() &&
    dayjs(task.end_time) < dayjs()
  ) {
    classes.push('late')
  }

  if (boardStore.get().taskGrabbingActivated && task.user_id != null) {
    classes.push('not-grabbing')
  }

  if (boardStore.get().grabbedTasks.findIndex((t) => t.id == task.id) >= 0) {
    classes.push('grabbing')
  }

  return classes.join(' ')
}
