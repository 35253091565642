<script>
  import axios from 'axios'
  import { onMount, onDestroy } from 'svelte'
  import { taskForm } from '@/stores/TaskFormStore'
  import { boardStore } from '@/stores/TaskBoardStore'
  import { getUserById } from '@/stores/UserStore'
  import { getCitizenById } from '@/stores/CitizenStore'
  import {
    Icon,
    CalendarDays,
    Clock,
    CheckCircle,
    PencilSquare,
    Trash,
  } from 'svelte-hero-icons'
  import dayjs from 'dayjs'
  import TaskForm from '@/components/Floweffect/TaskForm.svelte'

  export let showCompleteButton = true

  let editingTask = false
  $: user = getUserById($boardStore.selectedTask.user_id)
  $: citizen = getCitizenById($boardStore.selectedTask.citizen_id)

  onMount(() => {
    $taskForm = $boardStore.selectedTask
    // $boardStore.safeToReload = false
  })

  onDestroy(() => {
    $boardStore.safeToReload = true
  })

  function markTaskAsDone() {
    axios
      .put(`/floweffect/tasks/complete_tasks/${$boardStore.selectedTask.id}`, {
        task: {
          id: $boardStore.selectedTask.id,
          completed_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          start_time: $boardStore.selectedTask.start_time,
          end_time: $boardStore.selectedTask.end_time,
        },
      })
      .then(() => {
        $boardStore.showTaskModal = false
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function markTaskAsUndone() {
    axios
      .delete(`/floweffect/tasks/complete_tasks/${$boardStore.selectedTask.id}`)
      .then(() => {
        $boardStore.showTaskModal = false
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function deleteTask() {
    let message = $boardStore.selectedTask.recurring
      ? 'Er du sikker på at du vil slette hele serien af opgaver?'
      : 'Er du sikker på at du vil slette opgaven?'
    confirm(message)

    axios
      .delete(`/tasks/${$boardStore.selectedTask.id}`)
      .then(() => {
        $boardStore.showTaskModal = false
      })
      .catch((error) => {
        console.log(error)
      })
  }
</script>

{#if editingTask}
  <TaskForm
    editing={true}
    on:savedTask={() => {
      editingTask = false
    }}
  />
{:else}
  <div class="modalContent">
    <div class="contentContainer">
      <div>
        <strong>Medarbejder</strong>
        {#if user}
          <div class="personContainer">
            <img
              class="img-circle"
              src={user.avatar.navbar_thumb.url}
              alt="Medarbejder"
              height="50px"
              width="50px"
            />
            <div>{user.name}</div>
          </div>
        {:else}
          <div>Ingen medarbejder</div>
        {/if}
      </div>
      <div>
        <strong>Borger</strong>
        {#if citizen}
          <div class="personContainer">
            <img
              class="img-circle"
              src={citizen.avatar.thumb.url}
              alt="Borger"
              height="50px"
              æ
              width="50px"
            />
            <div>{citizen.name}</div>
          </div>
        {:else}
          <div>Ingen borger</div>
        {/if}
      </div>
    </div>

    <div class="contentContainer">
      <div>
        <strong>Beskrivelse</strong>
        <div>{$boardStore.selectedTask.description}</div>
      </div>
    </div>

    <div class="contentContainer">
      <div class="dateContainer text-muted">
        <div>Start</div>
        <div class="dateText">
          <Icon src={CalendarDays} size="18" />
          {dayjs($boardStore.selectedTask.start_time).format('LL')}
        </div>
        <div class="dateText">
          <Icon src={Clock} size="18" />
          {dayjs($boardStore.selectedTask.start_time).format('LT')}
        </div>
      </div>
      <div class="dateContainer text-muted">
        <div>Slut</div>
        <div class="dateText">
          <Icon src={CalendarDays} size="18" />
          {dayjs($boardStore.selectedTask.end_time).format('LL')}
        </div>
        <div class="dateText">
          <Icon src={Clock} size="18" />
          {dayjs($boardStore.selectedTask.end_time).format('LT')}
        </div>
      </div>
    </div>

    <div class="contentContainer" style="margin-bottom: 0px">
      <button
        class="btn btn-default"
        on:click={() => {
          editingTask = true
        }}
      >
        <Icon src={PencilSquare} size="18" />
        Rediger
      </button>
      <button
        class="btn btn-default"
        on:click={() => {
          deleteTask()
        }}
        confirm
      >
        <Icon src={Trash} size="18" />
        Slet
      </button>
      {#if showCompleteButton}
        {#if $boardStore.selectedTask.completed_at != null}
          <button
            class="btn btn-danger"
            on:click={() => {
              markTaskAsUndone()
            }}
          >
            <Icon src={CheckCircle} size="18" />
            Ikke udført
          </button>
        {:else}
          <button
            class="btn btn-success"
            on:click={() => {
              markTaskAsDone()
            }}
          >
            <Icon src={CheckCircle} size="18" />
            Udført
          </button>
        {/if}
      {/if}
    </div>
  </div>
{/if}

<style>
  .modalContent {
    padding: 20px;
  }
  .contentContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    flex-wrap: wrap;
  }

  .contentContainer > div {
    flex: 1;
  }

  .personContainer {
    display: flex;
    align-items: center;
  }

  .personContainer img {
    margin-right: 10px;
  }

  .dateContainer {
    font-size: 14px;
  }

  .dateText {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .btn {
    display: flex;
    align-items: center;
    gap: 5px;
  }
</style>
